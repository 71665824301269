import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: wpMediaItem(title: { eq: "Image-Part-1-CU" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				reviews {
					reviewCount
				}
				schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: TRACED_SVG
							)
						}
					}
				}
			}
		`
	);

	const reviewCount = data.reviews.reviewCount;

	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		name: "Dental House Pinner",
		legalName: "Dental House Pinner Limited",
		description:
			"Highest quality dental care in a warm and welcoming setting, offering the newest in digital dentistry",
		image:
			"https://dental-house-pinner.rjmdigital.net/wp-content/uploads/2023/09/Dentist-24.jpg",
		"@id": "https://www.dentalhousepinner.co.uk",
		slogan: "Quality dentistry from our warm and welcoming team",
		url: "https://www.dentalhousepinner.co.uk",
		telephone: "02088662140",
		priceRange: "$$$",
		areaServed: [
			{
				"@type": "City",
				name: "Pinner",
				sameAs: "https://en.wikipedia.org/wiki/Pinner",
			},
		],
		email: "info@dentalhousepinner.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "4",
		location: {
			"@type": "City",
			name: "Pinner",
			sameAs: "https://en.wikipedia.org/wiki/Pinner",
		},
		foundingLocation: {
			"@type": "City",
			name: "Pinner",
			sameAs: "https://en.wikipedia.org/wiki/Pinner",
		},
		address: {
			"@type": "PostalAddress",
			streetAddress: "450 Rayners Ln, Rayners Lane ",
			addressLocality: "Pinner",
			addressRegion: "Pinner",
			postalCode: "HA5 5DX",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: 51.5778007,
			longitude: -0.3736131,
		},
		hasMap:
			"https://www.google.com/maps/place/Dental+House+Pinner/@51.5778007,-0.3736131,17z/data=!3m1!4b1!4m20!1m13!4m12!1m4!2m2!1d-5.0659328!2d55.8432256!4e1!1m6!1m2!1s0x48761349fca7cce1:0x6be78fbbe28b1e0c!2sdental+house+pinner!2m2!1d-0.371084!2d51.5777974!3m5!1s0x48761349fca7cce1:0x6be78fbbe28b1e0c!8m2!3d51.5777974!4d-0.3710382!16s%2Fg%2F1t_kf_pq?entry=ttu",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "https://schema.org/Monday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "https://schema.org/Tuesday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "https://schema.org/Wednesday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "https://schema.org/Thursday",
				opens: "08:30",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "13:00",
				dayOfWeek: "https://schema.org/Friday",
				opens: "08:30",
			},
		],
		founder: {
			"@type": "Person",
			name: "Shamik Desai",
			id: "https://www.dentalhousepinner.co.uk/about#shamik-desai",
			url: "https://www.dentalhousepinner.co.uk/about",
			description: "Principal Dentist",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "0",
			ratingValue: "4.9",
			reviewCount: `${reviewCount}`,
			url: "https://www.dentalhousepinner.co.uk",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.dentalhousepinner.co.uk/contact-us",
			url: "https://www.dentalhousepinner.co.uk/contact-us",
			image:
				"https://dental-house-pinner.rjmdigital.net/wp-content/uploads/2023/09/Dentist-24.jpg",
			name: "Shamik Desai",
			description: "Contact point",
			telephone: "02088662140",
			contactType: "Contact center",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Pinner",
					sameAs: "https://en.wikipedia.org/wiki/Pinner",
				},
			],
		},
		logo: "https://dental-house-pinner.rjmdigital.net/wp-content/uploads/2023/09/White-on-Transparent.png",
		sameAs: [
			"https://www.facebook.com/DHPinner/",
			"https://www.instagram.com/dentalhouse.pinner",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
