import React from "react";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaLongArrowAltRight } from "react-icons/fa";

const CardHomeHero = ({
	image,
	title,
	text,
	url,
	imgAlt,
	imageHeight,
	headingHeight,
	shorter,
	objPos,
	span,
}) => {
	const colSpan = span || 3;
	return (
		<Col
			style={{ minHeight: "100%" }}
			className="mb-6 mb-xl-0"
			md={6}
			lg={5}
			xl={colSpan}
		>
			<div
				style={{
					boxShadow: "0px 3px 50px #00000029",
					background: "none",
					overflow: "hidden",
				}}
				className={` w-100 h-100 feature-box  pb-5 position-relative`}
			>
				<div className={`position-relative d-lg-none  `}>
					<div style={{ zIndex: 0, overflow: "hidden" }} className={`   `}>
						<GatsbyImage
							style={{ height: "220px" }}
							className="w-100"
							image={image}
							alt={imgAlt}
							objectPosition={objPos}
						/>
					</div>
				</div>
				<div
					style={{ height: imageHeight }}
					className={`position-relative d-none d-lg-block ${shorter} `}
				>
					<div
						style={{ zIndex: 0, overflow: "hidden" }}
						className={` position-absolute treatment-card-image-container top-0 `}
					>
						<GatsbyImage
							className="treatment-card-image w-100"
							image={image}
							alt={imgAlt}
						/>
					</div>
				</div>
				<div
					style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
					className="bg-white  px-4 d-lg-none pt-7 position-relative text-center "
				>
					<h3 className="fs-4 text-text-secondary">{title}</h3>
					<p>{text}</p>
					<Link
						to={url}
						className="fs-5 d-flex align-items-center justify-content-center gold-link"
					>
						VIEW MORE <FaLongArrowAltRight className="ms-2 fs-6" />
					</Link>
				</div>
				<div
					style={{ width: "calc(100% + 3px)", left: "-1px", zIndex: 2 }}
					className="bg-white  px-4 d-none d-lg-block pt-7 position-relative text-center card-div"
				>
					<h3 className="fs-4 text-text-secondary">{title}</h3>
					<p>{text}</p>
					<Link
						to={url}
						className="fs-5 d-flex align-items-center justify-content-center gold-link"
					>
						VIEW MORE <FaLongArrowAltRight className="ms-2 fs-6" />
					</Link>
				</div>
			</div>
		</Col>
	);
};

export default CardHomeHero;
