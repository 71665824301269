import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "./link";
import Button from "react-bootstrap/Button";
import gsap from "gsap";

const Hero = ({ image1, image2 }) => {
	useEffect(() => {
		gsap.to(".main-image-left-hero", {
			y: 0,
			duration: 2,
			delay: 0.2,
		});
		gsap.to(".main-image-right-hero", {
			y: 0,
			duration: 2,
			delay: 0.2,
		});
	}, []); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED
	return (
		<>
			<section
				style={{ height: "71vh", overflow: "hidden" }}
				className="position-relative w-100"
			>
				<div
					className=" w-100 h-100 bg-dark-blue position-absolute"
					style={{
						zIndex: 0,
						opacity: 0.7,
					}}
				></div>
				<div
					style={{ zIndex: -1 }}
					className="h-100 position-absolute d-lg-none"
				>
					<GatsbyImage className="h-100 w-100" image={image1} alt="" />
				</div>
				<div
					style={{ zIndex: -1 }}
					className="h-100 position-absolute d-none d-lg-block start-0 main-image-left-hero"
				>
					<GatsbyImage className="h-100 w-100" image={image1} alt="" />
				</div>
				<div
					style={{ zIndex: -1 }}
					className="h-100 position-absolute  d-none d-lg-block end-0 main-image-right-hero"
				>
					<GatsbyImage className="h-100 w-100" image={image2} alt="" />
				</div>
				<Container style={{ zIndex: 1 }} className="position-relative h-100">
					<Row className="h-100 align-items-center">
						<Col>
							<p className="text-white text-center text-md-start jost-bold fs-6">
								DENTAL PRACTICE IN PINNER
							</p>
							<h1 className="display-1 text-center text-md-start text-white">
								<span className="quicksand-light">Welcome to</span>
								<br />
								Dental House Pinner
							</h1>
							<Row className="pt-4">
								<Col>
									<Button
										variant="accent"
										className=" py-2 fs-5 w-100 w-md-auto me-4 text-white px-5 "
										href="https://dental-house-practice-dental-house-pinner.dentr.net/"
										target="_blank"
										rel="noreferrer"
									>
										Book an appointment
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
			<div className="bg-dark-blue w-100 text-center  d-xl-flex align-items-center justify-content-center home-hero-bottom">
				<p className=" mb-0 py-2 text-white py-xl-0 d-block">
					Denplan affordable monthly plans available -{" "}
					<Link className="jost-bold white-link-hero" to="/price-guide">
						click here
					</Link>{" "}
					to find out more
				</p>
			</div>
		</>
	);
};

export default Hero;
